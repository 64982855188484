var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "signupContainer" }, [
    _c("div", { staticClass: "infoBody" }, [
      _c(
        "div",
        { staticClass: "titleImg" },
        [_c("v-img", { attrs: { src: require("@/assets/titleImg.png") } })],
        1
      ),
      _vm._m(0),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "inputArea" },
        [
          _c("span", [_vm._v("マスター名")]),
          _c("br"),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.MasterName,
                expression: "MasterName",
              },
            ],
            attrs: {
              type: "text",
              placeholder: "例：アルパカ大郎（登録後に変更可能です）",
            },
            domProps: { value: _vm.MasterName },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.MasterName = $event.target.value
              },
            },
          }),
          _c("span", [_vm._v("マスター経歴")]),
          _c("br"),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.MasterCareer,
                expression: "MasterCareer",
              },
            ],
            attrs: {
              placeholder:
                "マスターとしての活動履歴をご記入ください。また、活動が確認できるURLなども記載いただければ参照させていただきます。",
            },
            domProps: { value: _vm.MasterCareer },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.MasterCareer = $event.target.value
              },
            },
          }),
          _c(
            "v-row",
            { staticClass: "marginAuto noPadding" },
            [
              _c("v-col", { attrs: { cols: "3" } }),
              _c(
                "v-col",
                { staticClass: "marginAuto noPadding", attrs: { cols: "1" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      value: "checked",
                      "unchecked-value": "not_checked",
                    },
                    model: {
                      value: _vm.riyouKiyaku,
                      callback: function ($$v) {
                        _vm.riyouKiyaku = $$v
                      },
                      expression: "riyouKiyaku",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "marginAuto noPadding", attrs: { cols: "8" } },
                [
                  _c("span", { staticClass: "pointer pinkColor" }, [
                    _vm._v("利用規約"),
                  ]),
                  _vm._v("に同意する\n        "),
                ]
              ),
            ],
            1
          ),
          _c("br"),
          _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n      "),
          _c(
            "v-btn",
            {
              staticClass: "signupButton",
              attrs: { color: "#f25d5d", width: "100%", height: "60px" },
              on: { click: _vm.signUpCreator },
            },
            [_vm._v("登録する")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "textCenter firstTitle" }, [
      _c("br"),
      _c("span", [_vm._v("マスター情報入力")]),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "textCenter secondTitle" }, [
      _c("span", [_vm._v("登録後、試験の案内をお送りいたします。")]),
      _c("br"),
      _c("span", [
        _vm._v("\n        業務や試験の詳細は\n        "),
        _c("span", { staticClass: "pointer pinkColor" }, [
          _vm._v("ガイドページ"),
        ]),
        _vm._v("\n        をご参照ください。\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }