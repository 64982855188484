var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sw-Loading_Box" },
    [
      _c("div", { staticClass: "sw-Loading_Inner" }, [_c("Loading")], 1),
      _c("CommonDialog", {
        ref: "errorDialog",
        attrs: { title: _vm.Msg_input_error, text: _vm.Msg_input_error_detail },
        on: { dialogReturn: _vm.dialogReturn },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }