<template>
  <div class="signupContainer">
    <div class="infoBody">
      <div class="titleImg">
        <v-img src="~@/assets/titleImg.png"></v-img>
      </div>
      <div class="textCenter firstTitle">
        <br />
        <span>クリエイター情報入力</span>
        <br />
      </div>
      <div class="textCenter secondTitle">
        <span>登録後、試験の案内をお送りいたします。</span>
        <br />
        <span>
          業務や試験の詳細は
          <span class="pointer pinkColor">ガイドページ</span>
          をご参照ください。
        </span>
      </div>
      <div class="inputArea">
        <span>クリエイター名</span>
        <br />
        <input
          type="text"
          placeholder="例：アルパカ大郎（登録後に変更可能です）"
          v-model="CreatorName"
        />
        <span>クリエイター経歴</span>
        <br />
        <textarea
          placeholder="クリエイターとしての活動履歴をご記入ください。また、活動が確認できるURLなども記載いただければ参照させていただきます。"
          v-model="CreatorCareer"
        />
        <v-row class="marginAuto noPadding">
          <v-col cols="3"></v-col>
          <v-col cols="1" class="marginAuto noPadding">
            <v-checkbox
              v-model="riyouKiyaku"
              value="checked"
              unchecked-value="not_checked"
            ></v-checkbox>
          </v-col>
          <v-col cols="8" class="marginAuto noPadding">
            <span class="pointer pinkColor">利用規約</span>に同意する
          </v-col>
        </v-row>
        <br />
        {{ errorMessage }}
        <v-btn
          class="signupButton"
          color="#f25d5d"
          width="100%"
          height="60px"
          @click="signUpCreator"
          >登録する</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";
export default {
  name: "newCreator",

  data() {
    return {
      userId: "",
      CreatorName: "",
      CreatorCareer: "",
      riyouKiyaku: "",
    };
  },

  created() {
    //Portal header使用
    this.$emit("changeHeaderVisible", false);

    this.$emit("checkWorldView", false);

    this.userId = firebase.auth().currentUser.uid;
  },
  mounted() {
    this.ngOnInit();
  },
  methods: {
    //firebaseを強制的に起動する
    ngOnInit() {
      this.userId = this.$store.state.userId;
    },

    signUpCreator: async function () {
      if (this.CreatorName === "") {
        this.errorMessage = "クリエイター名を入力してください";
      } else if (this.CreatorCareer === "") {
        this.errorMessage = "クリエイター経歴を入力してください";
      } else if (
        this.riyouKiyaku === "" ||
        this.riyouKiyaku === "not_checked"
      ) {
        this.errorMessage = "利用規約に同意してください";
      } else {
        this.userId = firebase.auth().currentUser.uid;
        //axiosでバックエンドを接続、ユーザ名前をルルムフDBに入れ
        await axios({
          method: "post",
          url: this.serverPath + "/api/user/updateUserName",
          timeout: "6000",
          data:
            "&userId=" +
            this.userId +
            "&userName=" +
            this.CreatorName +
            "&userZokusei=3",
        })
          .then((response) => {
            //// console.log(response);
            //this.$store.dispatch("userId", { userId: this.userId });
            ////ユーザ名入力されていたらメインページへ
            //this.$router.replace("/");
          })
          //接続エラー
          .catch((error) => {
            console.log(error);
            alert("セッションが切断しました、もう一度サインアップしてください");

            //接続エラーあったらサインアップページへ
            // this.$router.push({ path: "/" });

            //そしてfirebaseにユーザ情報を削除
            firebase
              .auth()
              .currentUser.delete()
              .then(function () {
                // User deleted.
              })
              .catch(function (error) {
                console.log(error);
              });
          });

        await axios({
          method: "post",
          url: this.serverPath + "/api/creator/addCreatorRequest",
          timeout: "6000",
          data:
            "&userId=" +
            this.userId +
            "&creatorName=" +
            this.CreatorName +
            "&creatorCareer=" +
            this.CreatorCareer +
            "&torokuUser=XXX",
        })
          .then((response) => {
            // console.log(response);
            this.$store.dispatch("userId", { userId: this.userId });
            //ユーザ名入力されていたらメインページへ
            this.$router.replace("/");
          })
          //接続エラー
          .catch((error) => {
            console.log(error);
            alert("セッションが切断しました、もう一度サインアップしてください");

            //接続エラーあったらサインアップページへ
            // this.$router.push({ path: "/" });

            //そしてfirebaseにユーザ情報を削除
            firebase
              .auth()
              .currentUser.delete()
              .then(function () {
                // User deleted.
              })
              .catch(function (error) {
                console.log(error);
              });
          });
      }
    },
  },
};
</script>
<style scoped>
.marginAuto {
  margin: auto;
}
.noPadding {
  padding: 0%;
}
div {
  margin: auto;
  margin-top: 20px;
}
.signupContainer {
  padding-top: 8%;
  padding-bottom: 30%;
  background-color: #f2ece7;
}
.infoBody {
  width: 30%;
  padding: 20px;
  margin: auto;
  background-color: white;
}
.firstTitle {
  position: relative;
  bottom: 40px;
  height: 50px;
  font-size: 25px;
  font-weight: 900;
  color: #5a4d48;
}
.secondTitle {
  font-size: 15px;
  font-weight: 700;
  color: #5a4d48;
}
input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: white;
  border: 1px lightgray solid;
}
textarea {
  width: 100%;
  height: 110px;
  padding: 10px;
  margin: 10px 0;
  background-color: white;
  border: 1px lightgray solid;
}
.textCenter {
  text-align: center;
}
.signupButton {
  font-size: 20px !important;
  font-weight: 600;
  color: white !important;
}
.pointer {
  cursor: pointer;
}
.pinkColor {
  color: #f36767;
}
</style>
