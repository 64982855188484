<template>
  <!-- <div class="bodyContainer">
    <h3>{{ msg }}</h3>
    <button @click="nextPage">次へ</button>
  </div>-->
  <div class="pSignup">
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <div class="pSignup-Wrap">
        <!-- <div class="pSignup-Copy_Main">みんなでつくり、みんなと生きる物語。</div> -->
        <div class="pSignup-Copy_Sub">
          <br />
          <span class="pSignup-Copy_Sub-ruby" v-html="msg"></span>
        </div>

        <!-- ログアップとログインの選択 -->
        <v-form class="pSignup-Form">
          <v-card outlined>
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-card-text>
                  <div class="pSignup-Form_Unit">
                    <div class="pSignup-Form_Label">メールアドレス</div>
                    <div class="pt-1 pb-4">{{ mailaddress }}</div>
                  </div>
                  <div class="pSignup-Form_Unit">
                    <div class="pSignup-Form_Label">
                      パスワード（半角英数字、6〜72文字）
                    </div>
                    <ValidationProvider
                      mode="eager"
                      :rules="{
                        required: true,
                        min: 6,
                        max: 72,
                        regex: /^[a-zA-Z0-9!#$%&()*+,.:;=?@\[\]\|^_{}~“’<>\/\\-]+$/,
                      }"
                      v-slot="{ errors }"
                      name="パスワード"
                      vid="password"
                    >
                      <v-text-field
                        v-model="password"
                        outlined
                        dense
                        :append-icon="show1 ? 'visibility' : 'visibility_off'"
                        :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"
                        :hide-details="errors.length === 0"
                        :error-messages="errors"
                      ></v-text-field>
                      <!-- 
                      <input
                        v-model="password"
                        class="pSignup-Form_Input"
                        outlined
                        type="password"
                        autocomplete="current-password"
                      /> -->
                    </ValidationProvider>
                  </div>
                  <div class="pSignup-Form_Unit mt-4">
                    <div class="pSignup-Form_Label">パスワード（確認）</div>
                    <ValidationProvider
                      rules="required|confirmed:password"
                      v-slot="{ errors }"
                      name="パスワード（確認）"
                      key="1"
                    >
                      <v-text-field
                        v-model="repassword"
                        outlined
                        dense
                        :append-icon="show2 ? 'visibility' : 'visibility_off'"
                        :type="show2 ? 'text' : 'password'"
                        @click:append="show2 = !show2"
                        :hide-details="errors.length === 0"
                        :error-messages="errors"
                      ></v-text-field>

                      <!-- <input
                        v-model="repassword"
                        class="pSignup-Form_Input"
                        outlined
                        type="password"
                        autocomplete="current-password"
                      /> -->
                    </ValidationProvider>
                  </div>

                  <!-- <div class="pSignup-Form_Btn">
                  <v-btn
                    block
                    large
                    depressed
                    dark
                    :color="ColorPrimary"
                    :loading="loadingBtn"
                    @click="nextpage"
                    >次へ</v-btn
                  >
                  </div>-->
                </v-card-text>
              </v-window-item>

              <!-- <v-window-item :value="2">
                <v-card-text>
                  <div class="pSignup-Form_Unit">
                    <div class="pSignup-Form_Label">生年月日</div>
                    <DateSelect
                      class="mt-2 dataSelect"
                      @dateChange="dateChange"
                    />
                  </div>
                  <div class="pSignup-Form_Unit">
                    <div class="pSignup-Form_Label">性別</div>
                    <v-radio-group v-model="selectedSex">
                      <v-radio
                        v-for="sexOption in sexOptions"
                        :key="sexOption.value"
                        :label="sexOption.text"
                        :value="sexOption.value"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </v-card-text>
              </v-window-item>-->

              <v-window-item :value="2">
                <v-list class="pt-8">
                  <template v-for="(item, index) in userClassList">
                    <v-list-item
                      :disabled="index != 0"
                      class="px-2 px-sm-4"
                      :key="index"
                      @click="nextpage(step, index)"
                    >
                      <v-list-item-avatar size="50" :color="ColorGray2">
                        <v-img
                          :src="`${assetsPath}/p/img/class/classIcon_${item.id}.png`"
                          width="50"
                          aspect-ratio="1"
                          id="img"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">{{
                          item.className
                        }}</v-list-item-title>
                        <v-list-item-content class="fs-xs pt-1 pb-0">{{
                          item.classText
                        }}</v-list-item-content>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      class="mx-8"
                      :key="index + 0.5"
                      v-if="(userClassList.length != index)"
                    />
                  </template>
                  <v-list-item>
                    <div class="fs-xs px-2">
                      ※クリエイター・マスター審査は別途お申し込みを受け付けております。詳しくは<a
                        href="https://alpaca-connect.com/recruitingForm"
                        target="_blank"
                        >こちら</a
                      >
                    </div>
                  </v-list-item>
                </v-list>
              </v-window-item>

              <v-window-item :value="3">
                <div class="d-flex justify-center py-10 my-10" v-if="loading">
                  <v-progress-circular
                    :size="80"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <template v-else>
                  <v-card-text>
                    <div class="fs-xs">プレイヤー名</div>
                    <ValidationProvider
                      rules="required|max:16"
                      v-slot="{ errors }"
                      name="プレイヤー名"
                    >
                      <v-text-field
                        v-model="userName"
                        outlined
                        dense
                        width="100px"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>

                    <div class="pSignup-Form_Unit">
                      <div class="pSignup-Form_Label">生年月日</div>
                      <DateSelect
                        class="mt-2 dataSelect"
                        @dateChange="dateChange"
                      />
                    </div>
                    <div class="pSignup-Form_Unit">
                      <div class="pSignup-Form_Label">性別</div>
                      <ValidationProvider rules="required" name="規約同意">
                        <v-radio-group v-model="selectedSex">
                          <v-radio
                            v-for="sexOption in sexOptions"
                            :key="sexOption.value"
                            :label="sexOption.text"
                            :value="sexOption.value"
                          ></v-radio>
                        </v-radio-group>
                      </ValidationProvider>
                    </div>

                    <div class>
                      <ValidationProvider rules="required" name="規約同意">
                        <v-checkbox
                          v-model="riyouKiyaku"
                          value="checked"
                          unchecked-value="not_checked"
                        >
                          <template v-slot:label>
                            <span class="fs-s">
                              <a
                                class="mx-2"
                                href="https://support.alpaca-connect.com/hc/ja/articles/360044065791"
                                target="_blank"
                                rel="noopener noreferrer"
                                @click.stop
                                >利用規約</a
                              > </span
                            >に同意する
                          </template>
                        </v-checkbox>
                        {{ $refs.obsPlayerName }}
                      </ValidationProvider>
                    </div>
                  </v-card-text>
                </template>
              </v-window-item>

              <v-window-item :value="4">
                <template>
                  <v-card-text>
                    <v-img
                      :src="`${assetsPath}/p/img/alpaca.png`"
                      width="90"
                      class="mx-auto my-4"
                    />

                    <div class="text-center">
                      アルパカコネクトへようこそ！
                      <br />さぁ、一緒にPBWを楽しみましょう
                    </div>
                  </v-card-text>
                  <div class="px-8 mb-8">
                    <v-btn large to="/" depressed block color="primary"
                      >ログイン</v-btn
                    >
                  </div>
                </template>
              </v-window-item>
            </v-window>

            <v-fade-transition>
              <v-alert
                v-if="errorMsg"
                transition="slide-x-transition"
                dense
                outlined
                class="mx-4"
                icon="error_outline"
                type="error"
                >{{ errorMsg }}</v-alert
              >
            </v-fade-transition>

            <!-- <v-divider class="mx-4"></v-divider> -->

            <v-card-actions v-if="step != 4">
              <v-btn :disabled="loading" v-if="step != 1" text @click="step--"
                >戻る</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                v-if="step == 1"
                color="primary"
                class="font-weight-bold"
                depressed
                :disabled="invalid"
                @click="nextpage(step)"
                >次へ</v-btn
              >

              <v-btn
                v-if="step == 3"
                color="primary"
                class="font-weight-bold"
                depressed
                :disabled="invalid || loading"
                @click="signUp"
                >登録する</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </ValidationObserver>

    <!-- エラーダイアログ -->
    <CommonDialog
      ref="errorDialog"
      title="登録に失敗しました"
      @alertDialogReturn="errorDialogReturn"
      :text="errorDialogMsg"
    ></CommonDialog>
  </div>
</template>

<script>
import axios from "axios";
import firebase from "firebase/app";
import DateSelect from "@/components/util/dateSelect";
import CommonDialog from "@/components/CommonDialog";
export default {
  name: "authOver",
  components: { DateSelect, CommonDialog },
  data() {
    return {
      loading: false,
      mailaddress: "",
      password: "",
      repassword: "",
      riyouKiyaku: "",
      next: true,
      loadingBtn: false,
      errorMsg: "",
      errorDialogMsg: "",
      step: 1,

      show1: false,
      show2: false,

      userId: "",
      userName: "",
      seinengappi: "",
      emailVerified: "",
      true: "true",
      radioRow: "",
      selectedSex: "",
      ageKbn: "",
      birthday: "",
      now: "",
      nowTime: "",
      birthDayTime: "",

      test: "",
      nextFlg: false,

      sexOptions: [
        { text: "男性", value: "1" },
        { text: "女性", value: "2" },
        { text: "その他", value: "0" },
      ],

      userClassList: [
        {
          id: 0,
          className: "プレイヤー",
          classText: "キャラクターを作ってPBWに参加しよう！",
        },
        {
          id: 1,
          className: "マスター",
          classText: "PBW世界のシナリオを提供しよう！",
        },
        {
          id: 2,
          className: "クリエイター",
          classText: "イラスト・ボイス・ノベルでPBWを彩ろう！",
        },
      ],
    };
  },

  computed: {
    msg() {
      if (this.step === 1) {
        return "ユーザー登録を開始します。<br>パスワードを入力して「次へ」を押してください";
      } else if (this.step === 2) {
        return "ユーザークラスを選択しよう！";
      } else if (this.step === 3) {
        return "あと少し！<br><span class='fs-xs'>プレイヤー名はマイページなどに表示されます</span>";
      } else if (this.step === 4) {
        return "<span class='fs-xl'>登録完了！</span>";
      }
      return "";
    },
  },

  created() {
    //時間の初処理
    this.now = new Date().toLocaleString();

    this.init();
  },
  methods: {
    init: async function () {
      try {
        /* 発注情報検索 */
        const res = await axios.get(this.serverPath + "/api/signup/init", {
          params: {
            temporaryId: this.$route.query.id,
          },
        });
        if (res.data) {
          this.mailaddress = res.data.mailaddress;
        }
        if (this.mailaddress == null) {
          this.$router.push("/");
        }
      } catch (error) {
        alert(error);
      }
    },

    nextpage(step) {
      this.errorMsg = "";
      this.step++;
    },

    dateChange(obj) {
      //コンポネントで出った生年月日の結果を整理
      if (obj.month.toString().length === 1) {
        var month = "0" + obj.month.toString();
      } else {
        month = obj.month.toString();
      }
      if (obj.day.toString().length === 1) {
        var day = "0" + obj.day.toString();
      } else {
        day = obj.day.toString();
      }

      this.seinengappi = obj.year.toString() + month + day;

      //年齢の計算（１年未満は0歳）
      var birthday = obj.year.toString() + "/" + month + "/" + day;
      this.birthDayTime = new Date(birthday);
      this.nowTime = new Date().getTime();
      var yeardiff =
        Math.ceil((this.nowTime - this.birthDayTime) / 31536000000) - 1;

      if (yeardiff < 16) {
        this.ageKbn = 4;
      } else if (yeardiff >= 16 && yeardiff < 18) {
        this.ageKbn = 3;
      } else if (yeardiff >= 18 && yeardiff < 20) {
        this.ageKbn = 2;
      } else {
        this.ageKbn = 1;
      }
    },

    //登録処理
    signUp: async function () {
      this.loading = true;
      await firebase
        .auth()
        .createUserWithEmailAndPassword(this.mailaddress, this.password)
        .then(() => {
          this.signUpAlpaca();
        })
        //ユーザ認証エラー
        .catch((error) => {
          console.log(error);
          if (error.code == "auth/email-already-in-use") {
            this.errorDialogMsg =
              "このメールアドレスはすでに登録されています。";
            this.loading = false;
            this.$refs.errorDialog.open();
          } else {
            this.errorDialogMsg =
              "登録に失敗しました。繰り返す場合はアルパカコネクトまでお問い合わせください。（" +
              message.errorMsg +
              "）";
            this.loading = false;
            this.$refs.errorDialog.open();
          }
        });
    },

    errorDialogReturn(answer) {
      if (answer) {
        //接続エラーあったらサインアップページへ
        this.$router.push({ path: "/" });

        //そしてfirebaseにユーザ情報を削除
        firebase
          .auth()
          .currentUser.delete()
          .then(function () {
            // User deleted.
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },

    //ユーザ情報をルルムフDBに入れ
    signUpAlpaca: async function () {
      //入力内容検証
      this.userId = firebase.auth().currentUser.uid;
      await axios({
        method: "post",
        url: this.serverPath + "/api/user/addUserInfo",
        timeout: "6000",
        data:
          "&userId=" +
          this.userId +
          "&seibetsu=" +
          this.selectedSex +
          "&seinengappi=" +
          this.seinengappi +
          "&accountStatus=0" +
          "&userName=" +
          this.userName +
          "&mailaddress=" +
          this.mailaddress +
          "&password=11223344" +
          "&userZokusei=1" +
          "&premiumKbn=0" +
          "&ageKbn=" +
          this.ageKbn +
          "&kessaiHouhou=0" +
          "&creatorKbn=0" +
          "&torokuUser=XXX",
      })
        .then((response) => {
          this.step++;
          this.$store.dispatch("statusChange", { statusChange: "1" });
        })
        //接続エラー
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.errorDialogMsg =
            "セッションが切断されました、もう一度サインアップしてください";
          this.$refs.errorDialog.open();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/import.scss";

.pSignup {
  min-height: 100vh;
  margin-top: -64px;
  background-color: var(--gray-2);

  &-Wrap {
    padding-top: 8vh;
    padding-right: 32px;
    padding-left: 32px;
    margin-top: 64px;
  }

  &-Copy {
    &_Main {
      width: 100%;
      padding-top: 23%;
      margin-bottom: 16px;
      overflow: hidden;
      line-height: 0;
      text-indent: 100%;
      white-space: nowrap;
      background: url("~@/assets/copy.png") no-repeat center;
      background-size: contain;

      @include media($sm) {
        padding-top: 113px;
        margin-bottom: 24px;
      }
    }

    &_Sub {
      font-size: $fs-m;
      font-weight: bold;
      line-height: 1.4;
      color: var(--gray-8);
      text-align: center;

      @include media($sm) {
        font-size: $fs-l;
        line-height: 1.2;
      }

      &-ruby {
        padding: 0 4px;
        font-size: $fs-xs;

        @include media($sm) {
          font-size: $fs-s;
        }
      }
    }
  }

  &-Form {
    min-width: 240px;
    max-width: 360px;
    margin: 16px -16px 40px;

    @include media($sm) {
      width: 80%;
      margin: 16px auto 40px;
    }

    &_Card {
      margin-right: -16px;
      margin-left: -16px;
      @include media {
        margin-right: 0;
        margin-left: 0;
      }
    }

    &_Label {
      font-size: $fs-xs;
    }

    &_Input {
      width: 100%;
      padding: 8px 16px;
      margin-bottom: 8px;
      background-color: #fff;
      border: solid 1px var(--c-border);
      border-radius: 4px;
    }

    &_Btn {
      padding: 16px 0 32px;
    }
  }

  &-Footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: $fs-xs;

    &_Common {
      display: flex;
      justify-content: space-between;
      padding: 8px 24px;
      background-color: var(--gray-8);

      a {
        color: var(--gray-2) !important;
      }
    }

    &_List {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-left: 0 !important;
      list-style: none;
    }

    &_ListUnit {
      & + & {
        margin-left: 16px;
      }
    }
  }
}
</style>
