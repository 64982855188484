<template>
  <div class="sw-Loading_Box">
    <div class="sw-Loading_Inner">
      <!-- <v-progress-linear
        class="sw-Loading_Linear"
        :color="ColorTextSub"
        dark
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>-->
      <Loading></Loading>
    </div>
    <!-- エラー時ダイアログ -->
    <CommonDialog
      ref="errorDialog"
      :title="Msg_input_error"
      :text="Msg_input_error_detail"
      @dialogReturn="dialogReturn"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";
import Loading from "@/components/Loading";
import CommonDialog from "@/components/CommonDialog";

export default {
  name: "loading",
  data() {
    return {
      timer: "",
      Msg_input_error: "",
      Msg_input_error_detail: "",
      lastClass: "",
    };
  },

  async created() {
    //Portal header使用
    // await this.$emit("changeHeaderVisible", true);
    // await this.$emit("checkWorldView", false);
    // this.timer = setInterval(this.toPortalTop, 500);
    this.toPortalTop();
  },
  components: {
    Loading,
    CommonDialog,
  },
  updated() {},
  methods: {
    toPortalTop: async function () {
      if (firebase.auth().currentUser.uid) {
        try {
          const idToken = await firebase.auth().currentUser.getIdToken(true);
          this.$store.dispatch("loginUser", {
            userId: firebase.auth().currentUser.uid,
            userIdToken: idToken,
          });
          const res = await axios({
            method: "get",
            url:
              this.serverPath +
              "/api/user/selectId?userId=" +
              firebase.auth().currentUser.uid,
          });
          if (res.data.userId == "") {
            this.$router.push({ path: "/login" });
            // clearInterval(this.timer);
          } else {
            const resClass = await axios.get(
              this.serverPath + "/api/userClass",
              {
                params: {
                  userId: this.$store.state.userId,
                },
              }
            );

            const lastClass = await axios.get(
              this.serverPath + "/api/userClass/getLastClass",
              {
                params: {
                  userId: this.$store.state.userId,
                },
              }
            );
            await this.$store.dispatch("publicUserId",{publicUserId:res.data.publicUserId})

            // clearInterval(this.timer);
            this.$router.push({ path: "/" }).catch((err) => {});
            // this.$store.dispatch('userId', {userId: idToken})

            this.updateStore(resClass, lastClass ? lastClass : 0);
          }
        } catch (error) {
          this.Msg_input_error = "ログインに失敗しました。";
          this.Msg_input_error_detail =
            "時間をおいて再度お試しください。何度も失敗する場合は、運営までお問い合わせください。";
          console.log(error);
          // clearInterval(this.timer);
          this.$store.dispatch("logoutUser");
          this.$refs.errorDialog.open();
        }
      }
      //BDに接続、userId検査条件で自分のuserIdを獲得（ユーザ存在チェック）
      // if (firebase.auth().currentUser.uid) {
      //   this.$store.dispatch("userId", {
      //     userId: firebase.auth().currentUser.uid
      //   });
      //   this.$router.push({ path: "/" });
      //   clearInterval(this.timer);
      // }
    },
    updateStore(resClass, lastClass) {
      try {
        if (resClass.data.userInfo) {
          this.$store.dispatch("userClassName", {
            userClassName: resClass.data.userInfo.userName,
          });
        }
        if (resClass.data.userInfo) {
          this.$store.dispatch("userClassIcon", {
            userClassIcon: resClass.data.userInfo.icon,
          });
        }
        if (resClass.data.masterInfo) {
          this.$store.dispatch("masterId", {
            masterId: resClass.data.masterInfo.masterId,
          });
          this.$store.dispatch("masterClassName", {
            masterClassName: resClass.data.masterInfo.masterName,
          });
          this.$store.dispatch("masterClassIcon", {
            masterClassIcon: resClass.data.masterInfo.icon,
          });
        }
        if (resClass.data.creatorInfoIllust) {
          this.$store.dispatch("illustClassName", {
            illustClassName: resClass.data.creatorInfoIllust.creatorName,
          });
          this.$store.dispatch("illustClassIcon", {
            illustClassIcon: resClass.data.creatorInfoIllust.icon,
          });
          this.$store.dispatch("illustClassId", {
            illustClassId: resClass.data.creatorInfoIllust.creatorId,
          });
        }
        if (resClass.data.creatorInfoVoice) {
          this.$store.dispatch("voiceClassName", {
            voiceClassName: resClass.data.creatorInfoVoice.creatorName,
          });
          this.$store.dispatch("voiceClassIcon", {
            voiceClassIcon: resClass.data.creatorInfoVoice.icon,
          });
          this.$store.dispatch("voiceClassId", {
            voiceClassId: resClass.data.creatorInfoVoice.creatorId,
          });
        }
        if (resClass.data.creatorInfoWriter) {
          this.$store.dispatch("writerClassName", {
            writerClassName: resClass.data.creatorInfoWriter.creatorName,
          });
          this.$store.dispatch("writerClassIcon", {
            writerClassIcon: resClass.data.creatorInfoWriter.icon,
          });
          this.$store.dispatch("writerClassId", {
            writerClassId: resClass.data.creatorInfoWriter.creatorId,
          });
        }
        if (resClass.data.userInfoAdmin) {
          this.$store.dispatch("adminClassName", {
            adminClassName: resClass.data.userInfoAdmin.userName,
          });
          this.$store.dispatch("adminClassIcon", {
            adminClassIcon: resClass.data.userInfoAdmin.icon,
          });
        }

        var ret = true;
        if (lastClass.data) {
          this.$store.dispatch("lastUsedClass", {
            lastUsedClass: lastClass.data.lastClass,
          });
        } else {
          ret = false;
        }

        if (ret == true) {
          switch (this.$store.state.lastUsedClass) {
            case 0: //Player
              this.$store.dispatch("userName", {
                userName: this.$store.state.userClassName,
              });
              this.$store.dispatch("userIcon", {
                userIcon: this.$store.state.userClassIcon,
              });
              this.$store.dispatch("adminFlag", {
                adminFlag: "0",
              });
              this.$store.dispatch("playerFlag", {
                playerFlag: "1",
              });
              this.$store.dispatch("masterFlag", {
                masterFlag: "0",
              });
              this.$store.dispatch("creatorFlag", {
                creatorFlag: "0",
              });
              this.$store.dispatch("creatorId", {
                creatorId: "",
              });
              break;
            case 1: //Master
              if (this.$store.state.masterClassName != "") {
                this.$store.dispatch("userName", {
                  userName: this.$store.state.masterClassName,
                });
              } else {
                ret = false;
                break;
              }
              if (this.$store.state.masterClassIcon != "") {
                this.$store.dispatch("userIcon", {
                  userIcon: this.$store.state.masterClassIcon,
                });
              }
              this.$store.dispatch("adminFlag", {
                adminFlag: "0",
              });
              this.$store.dispatch("playerFlag", {
                playerFlag: "0",
              });
              this.$store.dispatch("masterFlag", {
                masterFlag: "1",
              });
              this.$store.dispatch("creatorFlag", {
                creatorFlag: "0",
              });
              this.$store.dispatch("creatorId", {
                creatorId: "",
              });
              break;
            case 2: //Illustrator
              if (this.$store.state.illustClassName != "") {
                this.$store.dispatch("userName", {
                  userName: this.$store.state.illustClassName,
                });
              } else {
                ret = false;
                break;
              }
              if (this.$store.state.illustClassIcon != "") {
                this.$store.dispatch("userIcon", {
                  userIcon: this.$store.state.illustClassIcon,
                });
              }
              this.$store.dispatch("adminFlag", {
                adminFlag: "0",
              });
              this.$store.dispatch("playerFlag", {
                playerFlag: "0",
              });
              this.$store.dispatch("masterFlag", {
                masterFlag: "0",
              });
              this.$store.dispatch("creatorFlag", {
                creatorFlag: "1",
              });
              this.$store.dispatch("creatorId", {
                creatorId: resClass.data.creatorInfoIllust.creatorId,
              });
              break;
            case 3: //Voice
              if (this.$store.state.voiceClassName) {
                this.$store.dispatch("userName", {
                  userName: this.$store.state.voiceClassName,
                });
              } else {
                ret = false;
                break;
              }
              if (this.$store.state.voiceClassIcon) {
                this.$store.dispatch("userIcon", {
                  userIcon: this.$store.state.voiceClassIcon,
                });
              }
              this.$store.dispatch("adminFlag", {
                adminFlag: "0",
              });
              this.$store.dispatch("playerFlag", {
                playerFlag: "0",
              });
              this.$store.dispatch("masterFlag", {
                masterFlag: "0",
              });
              this.$store.dispatch("creatorFlag", {
                creatorFlag: "2",
              });
              this.$store.dispatch("creatorId", {
                creatorId: resClass.data.creatorInfoVoice.creatorId,
              });
              break;
            case 4: //Writer
              if (this.$store.state.writerClassName != "") {
                this.$store.dispatch("userName", {
                  userName: this.$store.state.writerClassName,
                });
              } else {
                ret = false;
                break;
              }
              if (this.$store.state.writerClassIcon != "") {
                this.$store.dispatch("userIcon", {
                  userIcon: this.$store.state.writerClassIcon,
                });
              }
              this.$store.dispatch("adminFlag", {
                adminFlag: "0",
              });
              this.$store.dispatch("playerFlag", {
                playerFlag: "0",
              });
              this.$store.dispatch("masterFlag", {
                masterFlag: "0",
              });
              this.$store.dispatch("creatorFlag", {
                creatorFlag: "3",
              });
              this.$store.dispatch("creatorId", {
                creatorId: resClass.data.creatorInfoWriter.creatorId,
              });
              break;
            case 5: //Admin
              if (this.$store.state.adminClassName != "") {
                this.$store.dispatch("userName", {
                  userName: this.$store.state.adminClassName,
                });
              } else {
                ret = false;
                break;
              }
              if (this.$store.state.adminClassIcon != "") {
                this.$store.dispatch("userIcon", {
                  userIcon: this.$store.state.adminClassIcon,
                });
              }
              this.$store.dispatch("adminFlag", {
                adminFlag: "1",
              });
              this.$store.dispatch("playerFlag", {
                playerFlag: "0",
              });
              this.$store.dispatch("masterFlag", {
                masterFlag: "0",
              });
              this.$store.dispatch("creatorFlag", {
                creatorFlag: "0",
              });
              this.$store.dispatch("creatorId", {
                creatorId: "",
              });
              break;
            default:
              break;
          }
        }

        // 問題が発生される場合デフォルトに設定する
        if (ret != true) {
          this.$store.dispatch("userName", {
            userName: this.$store.state.userClassName,
          });
          this.$store.dispatch("userIcon", {
            userIcon: this.$store.state.userClassIcon,
          });
          this.$store.dispatch("lastUsedClass", {
            lastUsedClass: null,
          });
          this.$store.dispatch("adminFlag", {
            adminFlag: "0",
          });
          this.$store.dispatch("playerFlag", {
            playerFlag: "0",
          });
          this.$store.dispatch("masterFlag", {
            masterFlag: "0",
          });
          this.$store.dispatch("creatorFlag", {
            creatorFlag: "0",
          });
          this.$store.dispatch("creatorId", {
            creatorId: "",
          });
        }
      } catch (error) {
        alert(error);
      }
    },
    dialogReturn() {
      this.$router.push({ name: "signup" }).catch((err) => {});
    },
  },
};
</script>

<style scoped></style>
