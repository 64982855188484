<template>
  <div class="pSignup">
    <div class="pSignup-Wrap">
      <div class="pSignup-Copy_Main">みんなでつくり、みんなと生きる物語。</div>
      <div class="pSignup-Copy_Sub">
        アルパカコネクトで
        <br />PBW
        <span class="pSignup-Copy_Sub-ruby">(プレイ・バイ・ウェブ)</span
        >を、はじめよう。
      </div>
      <!-- ログアップとログインの選択 -->
      <v-slide-x-reverse-transition hide-on-leave>
        <div
          @keydown.enter.stop="signUpMail"
          v-if="logType === 'signup'"
          class="pSignup-Form"
        >
          <div class="pSignup-Form_Unit">
            <div class="pSignup-Form_Label">メールアドレス</div>
            <input
              v-model="mailaddress"
              class="pSignup-Form_Input"
              outlined
              type="text"
              autocomplete="username"
            />
          </div>
          <!-- <div class="pSignup-Form_Unit">
          <div class="pSignup-Form_Label">パスワード</div>
          <input
            v-model="password"
            class="pSignup-Form_Input"
            outlined
            type="password"
            autocomplete="current-password"
          />
        </div>-->
          <v-fade-transition>
            <v-alert
              v-if="errorMsg"
              transition="slide-x-transition"
              dense
              outlined
              icon="error_outline"
              type="error"
              >{{ errorMsg }}</v-alert
            >
          </v-fade-transition>
          <div class="pSignup-Form_Btn">
            <v-btn
              block
              large
              depressed
              dark
              :color="ColorPrimary"
              :loading="loadingBtn"
              @click="signUpMail"
              >ユーザー登録</v-btn
            >
          </div>
        </div>
      </v-slide-x-reverse-transition>
      <v-slide-x-reverse-transition hide-on-leave>
        <div
          v-if="logType === 'passwordReset'"
          class="pSignup-Form"
          @keydown.enter.stop="passwordReset"
        >
          <div class="pSignup-Form_Unit">
            <div class="fs-s my-8 text-center">
              パスワードの<span class="font-weight-bold">再設定メール</span
              >をお送りします。<br />ご登録のメールアドレスを入力してください。
            </div>
            <div class="pSignup-Form_Label">ご登録メールアドレス</div>
            <input
              v-model="mailaddress"
              class="pSignup-Form_Input"
              outlined
              type="text"
              autocomplete="username"
            />
          </div>
          <v-fade-transition>
            <v-alert
              v-if="errorMsg"
              transition="slide-x-transition"
              dense
              outlined
              icon="error_outline"
              type="error"
              >{{ errorMsg }}</v-alert
            >
          </v-fade-transition>
          <div class="pSignup-Form_Btn">
            <v-btn
              block
              large
              depressed
              dark
              :color="ColorPrimary"
              :loading="loadingBtn"
              @click="passwordReset"
              >送信</v-btn
            >
          </div>
        </div>
      </v-slide-x-reverse-transition>
      <v-slide-x-reverse-transition hide-on-leave>
        <div v-if="logType === 'sendedResetMail'">
          メールを送信しました。
        </div>
      </v-slide-x-reverse-transition>
      <v-slide-x-reverse-transition hide-on-leave>
        <div
          @keydown.enter.stop="signInMail"
          v-if="logType === 'signin'"
          class="pSignup-Form"
        >
          <div
            class="my-8 text-center font-weight-bold"
            v-if="sendedMailaddress"
          >
            <span class="fs-l pr-1">{{ sendedMailaddress }}</span>
            <span class="fs-s">宛に</span>
            <br />
            <span class="fs-s" v-if="msgflg == 1"
              >登録案内のメールを送信しました。</span
            >
            <span class="fs-s" v-else-if="msgflg == 2"
              >パスワード再設定メールを送信しました。</span
            >
          </div>

          <div class="pSignup-Form_Unit">
            <div class="pSignup-Form_Label">メールアドレス</div>
            <input
              v-model="mailaddress"
              class="pSignup-Form_Input"
              outlined
              type="text"
              autocomplete="username"
            />
          </div>
          <div class="pSignup-Form_Unit">
            <div class="pSignup-Form_Label">パスワード</div>
            <input
              v-model="password"
              class="pSignup-Form_Input"
              outlined
              type="password"
              autocomplete="current-password"
            />
            <div class="text-right">
              <v-btn
                text
                small
                @click="
                  logType = 'passwordReset';
                  sendedMailaddress = '';
                "
                class="logSwitch"
                >パスワードがわからない</v-btn
              >
            </div>
          </div>
          <v-fade-transition>
            <v-alert
              v-if="errorMsg"
              transition="slide-x-transition"
              dense
              outlined
              icon="error_outline"
              type="error"
              >{{ errorMsg }}</v-alert
            >
          </v-fade-transition>
          <div class="pSignup-Form_Btn">
            <v-btn
              block
              large
              depressed
              dark
              :color="ColorPrimary"
              :loading="loadingBtn"
              @click="signInMail"
              >ログイン</v-btn
            >
          </div>
        </div>
      </v-slide-x-reverse-transition>

      <div class="textCenter socialBtnArea">
        <!-- <span class="textFont">ソーシャルアカウントで登録</span>
        <br />

        <SocialLogin></SocialLogin>

        <hr />-->
        <div class="text-center">
          <span class="textFont" v-if="logType === 'signup'">
            登録済みの方は
            <v-btn
              text
              @click="
                logType = 'signin';
                sendedMailaddress = '';
              "
              >ログイン</v-btn
            ></span
          >

          <span
            class="textFont"
            v-if="logType === 'signin' || logType === 'passwordReset'"
          >
            未登録の方は
            <v-btn
              text
              @click="
                logType = 'signup';
                sendedMailaddress = '';
              "
              class="px-2 fs-m fc-primary"
              >ユーザー登録</v-btn
            >
          </span>
        </div>
        <br />
        <br />
        <br />
      </div>
      <SpecialThanks />
    </div>

    <CommonDialog ref="commonDialog" :title="dialogTitle">
      <p class="mb-0">{{ dialogMsg }}</p>
    </CommonDialog>
  </div>
</template>

<script>
import firebase from "firebase/app";
import axios from "axios";
import SocialLogin from "@/components/util/socialLogin";
import CommonDialog from "@/components/CommonDialog";
import SpecialThanks from "@/views/specialThanks";

export default {
  inject: ["reload"],
  name: "Signup",
  components: { SocialLogin, CommonDialog, SpecialThanks },
  data() {
    return {
      userId: "",
      password: "",
      mailaddress: "",
      sendedMailaddress: "",
      captchaToken: "",
      ui: "",
      showSocialButton: false,
      logType: "signin",
      idToken: "",
      rediectUrl: "",
      freshed: false,
      test: "",
      errorMsg: "",
      loadingBtn: false,
      msgflg: false,

      dialogTitle: "",
      dialogMsg: "",
    };
  },
  beforeCreate() {
    if (this.freshed === false) {
      // this.$router.go(0);
      this.reload();
      this.freshed = true;
    }
  },
  created() {
    //header使用
    this.$emit("changeHeaderVisible", true);
    this.$emit("checkWorldView", false);
  },

  methods: {
    signUpMail: async function () {
      this.errorMsg = "";
      this.loadingBtn = true;
      try {
        /* 発注情報検索 */
        const res = await axios.get(this.serverPath + "/api/signup/check", {
          params: {
            mailaddress: this.mailaddress,
          },
        });
        if (res.data) {
          if (res.data.check == "1") {
            this.errorMsg = "このメールアドレスは、すでに登録されています。";
            this.loadingBtn = false;
          } else {
            await axios({
              method: "post",
              url: this.serverPath + "/api/signup/first",
              data: "&mailaddress=" + this.mailaddress,
            })
              .then((response) => {
                // console.log(response);
                // this.$store.dispatch("userId", { userId: this.userId });
                // 処理成功時はマイページへ
                this.loadingBtn = false;
                this.sendedMailaddress = this.mailaddress;
                this.mailaddress = "";
                // this.errorMsg = 1;
                this.logType = "signin";
                this.msgflg = true;
              })
              //接続エラー
              .catch((error) => {
                console.log(error);
                this.loadingBtn = false;
                this.errorMsg =
                  "セッションが切断されました。時間をおいてお試しください。";

                //接続エラーあったらサインアップページへ
                // this.$router.push({ path: "/" });
              });
          }
        }
      } catch (error) {
        alert(error);
      }
    },

    //パスワードのリセット
    passwordReset() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.mailaddress)
        .then(() => {
          this.sendedMailaddress = this.mailaddress;
          this.mailaddress = "";
          this.msgflg = 2;
          this.errorMsg = "";
          this.logType = "signin";
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorMessage);
          switch (errorMessage) {
            case "The email address is badly formatted.":
              this.errorMsg =
                "メールアドレスの形式が正しくありません。入力内容をお確かめください。";
              break;
            case "There is no user record corresponding to this identifier. The user may have been deleted.":
              this.errorMsg =
                "メールアドレスのご登録が確認できません。入力内容をお確かめください。";
              break;
            default:
              this.errorMsg = "メールアドレスが正しいかお確かめください。";
              break;
          }
        });
    },

    signInMail: function () {
      this.loadingBtn = true;
      this.errorMsg = "";
      // this.$recaptcha("login").then(token => {
      //googleから獲得したToken
      // this.captchaToken = token;
      //TokenをrestTemplateAPIにリクエストしで、認証する
      // axios({
      //   method: "post",
      //   url: this.serverPath + "/api/reCaptcha/verify",
      //   data: "&captchaToken=" + this.captchaToken
      // })
      //   .then(response => {
      //     //ユーザはボットがどうかを判断
      //     if (response.data.token === "valid") {
      // Firebase Authenticationでログイン
      firebase
        .auth()
        .signInWithEmailAndPassword(this.mailaddress, this.password)
        .then(
          () => {
            this.loadingBtn = false;
            this.$router.push("/loading");
          },

          async (user) => {
            const uid = user.uid;
            //BDに接続、userId検査条件で自分のuserIdを獲得（ユーザ存在チェック）
            try {
              const res = await axios({
                method: "get",
                url: this.serverPath + "/api/user/selectId?userId=" + uid,
              });
              if (res.data === "") {
                this.errorMsg =
                  "メールアドレスとパスワードを正しく入力してください。大文字と小文字を区別する必要があります。";
                this.loadingBtn = false;
              } else {
                //userIdあるならメインページへ
                this.$store.dispatch("userId", { userId: uid });
                this.$store.dispatch("publicUserId",{publicUserId:res.data.publicUserId})
                this.$router.push("/");
              }
            } catch (error) {
              this.errorMsg =
                "メールアドレスとパスワードを正しく入力してください。大文字と小文字を区別する必要があります。";
              this.loadingBtn = false;
            }
          },
          // //firebase認証エラー
          (err) => {
            const error = err.message;
            switch (error) {
              case "The email address is badly formatted.":
                this.errorMsg = "メールアドレスが正しいかお確かめください。";
                break;
              case "The password is invalid or the user does not have a password.":
                this.errorMsg = "メールアドレスが正しいかお確かめください。";
                break;
              default:
                this.errorMsg =
                  "メールアドレスとパスワードが正しいかお確かめください。";
                break;
            }
            this.loadingBtn = false;
          }
        );
      // } else {
      //   //ユーザはボットなら登録認証を実行しない
      //   alert("認証接続エラー１");
      // }
      // })
      // //ボット認証リクエストエラー
      // .catch(error => {
      //   alert("認証接続エラー２");
      //   // console.log(error);
      // });
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/import.scss";

.pSignup {
  min-height: 90vh;
  background-color: var(--gray-2);

  &-Wrap {
    padding-top: 16vh;
    padding-right: 32px;
    padding-left: 32px;
  }

  &-Copy {
    &_Main {
      width: 100%;
      padding-top: 23%;
      margin-bottom: 16px;
      overflow: hidden;
      line-height: 0;
      text-indent: 100%;
      white-space: nowrap;
      background: url("~@/assets/copy.png") no-repeat center;
      background-size: contain;

      @include media($sm) {
        padding-top: 113px;
        margin-bottom: 24px;
      }
    }

    &_Sub {
      font-size: $fs-m;
      font-weight: bold;
      line-height: 1.4;
      color: var(--gray-8);
      text-align: center;

      @include media($sm) {
        font-size: $fs-l;
        line-height: 1.2;
      }

      &-ruby {
        padding: 0 4px;
        font-size: $fs-xs;

        @include media($sm) {
          font-size: $fs-s;
        }
      }
    }
  }

  &-Form {
    width: 80%;
    min-width: 240px;
    max-width: 360px;
    margin: 16px auto;

    &_Label {
      font-size: $fs-xs;
    }

    &_Input {
      width: 100%;
      padding: 8px 16px;
      margin-bottom: 8px;
      background-color: #fff;
      border: solid 1px var(--c-border);
      border-radius: 4px;
    }

    &_Btn {
      padding: 16px 0 8px;
    }
  }
}
</style>
